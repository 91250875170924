@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
  font-family: 'Inter', sans-serif;
}

.map-container {
  width: 100%;
  height: 60vh;
}
/* Login page */

.topbanner {
background-image: url("./Assets/home/home_banner.jpg");
  object-fit: contain;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 50vh;
}

.membership-box{
  backdrop-filter: blur(6px) saturate(180%);
  -webkit-backdrop-filter: blur(6px) saturate(166%);
  background-color: rgba(0, 0, 0, 0.603);
  height:50vh;
  
}
.searchbar{
  margin-top: -1%;
}

@media (max-width: 385px) {
  .topbanner{
    overflow: hidden;
  }
  .membership-box{
    height:90vh;
  }
  
  .searchbar{
    margin-top: -1.5%;
  }
  .mapbtn{
    margin: -1%;
  }
  .hostelowner{
    display: none;
  }
  .cardrespon{
    display: flex;
    flex-direction: column;
  }

}

@media (max-width: 600px) {
  .membership-box{
    height:60vh;
    width: 60%;
  }
  .topbanner{
    height: 55vh;
    width: 650px;
    font-size: small;
    overflow: hidden;
    
  } 
  .searchbar{
    width: 100%;
    margin-top: -13%;
  }

  .respon{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .respondash {
    display: flex;
    flex-direction: row;
    height: 10px;
    text-align: center;
    
  }
  .siderespon{
    display: flex;
    flex-direction: column;
  }
  .hostelowner{
    display: none;
  }
  .cardrespon{
    display: flex;
    flex-direction: column;
  }
}


@media (max-width: 400px) {
  .sidebar {
    width: 20%;
    display: flex;
    flex-direction: row;
  }
  .respon{
    display: flex;
    flex-direction: column;
  }
  .respondash {
    width: 10px;
    margin: 0;
    height: 30px;
  }
  .topbanner{
    width: 100%;
  }
  .membership-box{
    width: 100%;
  }
  .cardrespon{
    display: flex;
    flex-direction: column;
  }
}

/* modal */
.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49,49,49,0.8);
}
.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}


.faq-drawer {
  margin-bottom: 30px;
}

.faq-drawer__content-wrapper {
  font-size: 1.25em;
  line-height: 1.4em;
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease-in-out;
}

.faq-drawer__title {
  border-top: #000 1px solid;
  cursor: pointer;
  display: block;
  font-size: 1.25em;
  font-weight: 700;
  padding: 30px 0 0 0;
  position: relative;
  margin-bottom: 0;
  transition: all 0.25s ease-out;
}

.faq-drawer__title::after {
  border-style: solid;
  border-width: 1px 1px 0 0;
  content: " ";
  display: inline-block;
  float: right;
  height: 10px;
  left: 2px;
  position: relative;
  right: 20px;
  top: 2px;
  transform: rotate(135deg);
  transition: 0.35s ease-in-out;
  vertical-align: top;
  width: 10px;
}

/* OPTIONAL HOVER STATE */
.faq-drawer__title:hover { 
  color: #727272  ;
}

.faq-drawer__trigger:checked
  + .faq-drawer__title
  + .faq-drawer__content-wrapper {
  max-height: 350px;
}

.faq-drawer__trigger:checked + .faq-drawer__title::after {
  transform: rotate(-45deg);
  transition: 0.25s ease-in-out;
}

input[type="checkbox"] {
  display: none;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 30px;
  }
}

/* Hotel owner page */
.hotel-bg{
background-image: url("./Assets/Hotelowner/hotelbgimg.svg");
}

.nav{
  font-size: large;
}
ul.nav {
  width: 100%;
  margin: 0 auto 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 90%;
  }
}
ul.nav li {
  width: 70%;
  padding: 1rem;
  list-style: none;
  text-align: center;
  cursor: pointer;
  transition: all 0.7s;
  
}
ul.nav li:nth-child(2) {
  border-radius: 0;
  
}
ul.nav li:hover {
  color: rgb(13, 130, 226);
}
ul.nav li.active {
  color: #39A2DB;
  font-weight: bold;
}